body {
  background-color: darkslategray !important;
  padding: 20px;
  padding-top: 70px;
  padding-bottom: 70px;
}


.loading {
  z-index: 1000;
}

a.nav-link {
  font-size: 0.9em !important;
}
